import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, LayersControl, ScaleControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import L from 'leaflet';
import '../styles/Map.css';
import '../styles/Popup.css';

const { BaseLayer } = LayersControl;

const MiniMapControl = () => {
    const map = useMap();

    useEffect(() => {
        const miniMapLayer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors',
        });

        const miniMap = new L.Control.MiniMap(miniMapLayer, {
            toggleDisplay: true,
            minimized: false,
            position: 'bottomright',
            width: 150,
            height: 150,
        }).addTo(map);

        return () => {
            miniMap.remove();
        };
    }, [map]);

    return null;
};

const CustomPopup = ({ marker }) => {
    return (
        <div className="popup-content">
            <img src={marker.image} alt={marker.title} className="popup-image" />
            <h2 className="popup-title">{marker.title}</h2>
            <p className="popup-description">{marker.description}</p>
            <a href={marker.moreInfoLink} className="popup-link" target="_blank" rel="noopener noreferrer">
                More Info
            </a>
        </div>
    );
};

// Solución para el icono por defecto
const defaultIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const Map = ({ markers }) => {
    return (
        <MapContainer
            center={[38.505277777778, -0.23277777777778]}
            zoom={7}
            className="map"
            fullscreenControl={true}
        >
            <LayersControl position="topright">
                <BaseLayer  name="Mapbox Satellite">
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWlndWVsemFyYWdvemFlc3F1ZXJkbyIsImEiOiJja2szNHdreG0wcHN4MnZxb2E0dzhtMTY2In0.WPnQXCR3lmry6KGqclie2A"
                        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        tileSize={512}
                        zoomOffset={-1}
                    />
                </BaseLayer>
                <BaseLayer  checked name="OpenStreetMap">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </BaseLayer>
            </LayersControl>

            {markers.map((marker) => (
                <Marker key={marker.id} position={marker.position} icon={defaultIcon}>
                    <Popup>
                        <CustomPopup marker={marker} />
                    </Popup>
                </Marker>
            ))}

            <ScaleControl position="bottomleft" />

            {/* MiniMapControl sería reemplazado aquí si decidieras usar un minimapa manual */}
        </MapContainer>
    );
};

export default Map;
