import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer id='contact' className="footer">
            <p>&copy; 2024 Grupo de exploracion de minerales y yacimientos. Correo: info.grupogemy@gmail.com. All Rights Reserved.</p>
        </footer>
    );
};

export default Footer;
