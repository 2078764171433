import React from 'react';
import Map from '../components/Map';
import '../styles/MapPage.css';

const MapPage = ({ markers }) => {
    return (
        <div className="map-page">
            <Map markers={markers} />
        </div>
    );
};

export default MapPage;
