import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BlogPost.css';

const BlogPost = ({ posts }) => {
    const { id } = useParams();
    const post = posts.find(p => p.id === parseInt(id));

    if (!post) {
        return <p>Post no encontrado.</p>;
    }

    return (
        <div className="blog-post-detail">
            <header className="blog-post-header">
                <h1>{post.title}</h1>
                <p className="blog-post-date">{post.date}</p>
            </header>
            <img src={post.image} alt={post.title} className="blog-post-detail-image" />
            <div className="blog-post-detail-content">
                <p>{post.content}</p>
            </div>
        </div>
    );
};

export default BlogPost;
