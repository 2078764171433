import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BlogList.css';

const BlogList = ({ posts }) => {
    return (
        <div className="blog-list">
            <header className="blog-header">
                <h1>Blog</h1>
                <p>Descubre las últimas expediciones y Hallazgos.</p>
            </header>
            <div className="blog-posts">
                {posts.map(post => (
                    <div key={post.id} className="blog-post">
                        <img src={post.image} alt={post.title} className="blog-post-image" />
                        <div className="blog-post-content">
                            <h2 className="blog-post-title">{post.title}</h2>
                            <p className="blog-post-excerpt">{post.excerpt}</p>
                            <Link to={`/blog/${post.id}`} className="read-more">Leer más</Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
