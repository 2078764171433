import React from 'react';
import BlogList from '../components/BlogList';

const BlogPage = ({ posts }) => {
    return (
        <div className="blog-page">
            <main>
                <BlogList posts={posts} />
            </main>
        </div>
    );
};

export default BlogPage;

