import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';
import Petrer from './asserts/Petrer.jpg'
import Tibi from './asserts/Tibi.jpg'
import Cofrentes from './asserts/Cofrentes.jpg'
import Orxeta from './asserts/Orxeta.jpeg'
import Finestrat from './asserts/Finestrat.jpeg'
import './styles/global.css';

const App = () => {

  const posts = [
    {
      id: 1,
      title: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      date: '2024-03-15',
      image: Finestrat,
      excerpt: 'Una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro',
      content: `
            A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela. Estos cristales, una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro, son altamente apreciados tanto por su belleza como por su valor mineralógico.

Los Jacintos de Compostela se forman en rocas sedimentarias, principalmente en arenas y arcillas, donde los procesos de cementación y oxidación les confieren su característico tono rojizo, que puede variar desde un naranja intenso hasta un rojo oscuro. Estos cristales suelen tener un hábito prismático, con caras bien definidas y bordes afilados, lo que les otorga un gran atractivo visual.

La carretera de Orxeta-Finestrat es conocida entre coleccionistas y geólogos por la calidad excepcional de los Jacintos de Compostela que se encuentran en la zona. Los ejemplares extraídos aquí son valorados por su tamaño, brillo y color, haciéndolos muy deseados en el ámbito de la mineralogía.

Históricamente, estos cristales han sido recolectados no solo por su belleza, sino también por su supuesta capacidad de atraer la buena suerte, lo que les ha conferido un valor cultural además del científico. Aunque la extracción en esta área ha sido a pequeña escala, los Jacintos de Compostela de Orxeta-Finestrat han contribuido al conocimiento geológico regional, permitiendo estudiar los procesos sedimentarios y diagenéticos que dan lugar a la formación de estos cristales.
        `
    },
    {
      id: 2,
      title: 'Argonitos en Loma Badá, Petrer',
      date: '2024-07-15',
      image: Petrer,
      excerpt: 'El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio',
      content: `
            Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito. El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio, siendo las otras dos la calcita y la vaterita. Este mineral se caracteriza por su estructura ortorrómbica y su hábito cristalino, que a menudo forma cristales aciculares o prismáticos.

En Loma Badá, el aragonito se presenta en una variedad de formas y colores, que van desde el blanco puro hasta tonos más oscuros, dependiendo de las impurezas presentes durante su formación. Los yacimientos en esta zona son de particular interés para los coleccionistas y geólogos debido a la calidad y el tamaño de los cristales, que pueden alcanzar dimensiones considerables y mostrar una transparencia notable.

La extracción del aragonito en Loma Badá ha sido objeto de estudio tanto por su valor mineralógico como por su importancia en la geología regional. Este mineral se forma en ambientes geológicos específicos, generalmente en condiciones de baja temperatura y alta presión, lo que lo convierte en un indicador clave de procesos geológicos en la región.
        `
    },
    {
      id: 3,
      title: 'Azufre en la mina San Francisco, Tibi',
      date: '2024-08-10',
      image: Tibi,
      excerpt: 'El azufre en su forma nativa, se caracteriza por su distintivo color amarillo y su fragilidad.',
      content: `
            La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre. Este mineral, fundamental en diversas industrias, se presenta en esta mina en su forma nativa, caracterizada por su distintivo color amarillo y su fragilidad.

El azufre de la mina San Francisco ha sido históricamente importante tanto para la economía local como para estudios geológicos. Su presencia en Tibi se debe a procesos geotérmicos y volcánicos que facilitaron la acumulación de azufre en esta zona, lo que convierte a la mina en un lugar de interés para geólogos y mineralogistas.

Los yacimientos de azufre en la mina San Francisco se distinguen por la pureza del mineral, que en algunos casos alcanza niveles elevados, haciendo que los cristales sean muy apreciados. Además, este azufre ha sido utilizado tradicionalmente en la producción de ácido sulfúrico, en la vulcanización del caucho, y en la fabricación de productos químicos.
        `
    },
    {
      id: 4,
      title: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      date: '2024-04-05',
      image: Orxeta,
      excerpt: 'Calcita, un mineral de los más comunes en la corteza terrestre, destacan por su pureza y brillo',
      content: `
            El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita. Este mineral, uno de los más comunes en la corteza terrestre, se presenta en este lugar en forma de cristales bien formados que destacan por su pureza y brillo.

La calcita es un mineral de carbonato de calcio que se encuentra en una amplia variedad de formas y colores, dependiendo de las impurezas y las condiciones de formación. En el depósito de Keuper, la calcita aparece típicamente en tonos que van desde el blanco translúcido hasta el amarillo pálido, con cristales que pueden adoptar formas romboédricas o escalenoédricas, las cuales son características de este mineral.

El depósito de Keuper es parte de una formación geológica más amplia que se desarrolló durante el período Triásico, cuando se acumularon grandes cantidades de sedimentos en condiciones marinas y lacustres. La calcita en esta zona se originó principalmente a partir de la precipitación química de carbonato de calcio en ambientes evaporíticos, lo que ha dado lugar a los depósitos masivos que se observan hoy en día.

Este yacimiento es de especial interés tanto para geólogos como para coleccionistas, ya que ofrece una excelente oportunidad para estudiar los procesos sedimentarios y diagenéticos que ocurren en ambientes evaporíticos. Además, la calcita extraída de esta área es valorada por su uso en la industria, especialmente en la producción de cemento, cal y otros materiales de construcción.


        `
    },
    {
      id: 5,
      title: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      date: '2024-8-5',
      image: Cofrentes,
      excerpt: 'Un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita',
      content: `
            El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita. Este volcán, uno de los pocos activos en la península ibérica en épocas geológicas pasadas, es una muestra destacada del vulcanismo en la región.

El olivino es un mineral que se encuentra comúnmente en rocas ígneas, especialmente en basaltos, y es reconocido por su color verde oliva y su estructura cristalina. En el Cerro de Agras, el olivino se presenta en forma de cristales bien formados, lo que lo convierte en un atractivo tanto para geólogos como para coleccionistas.

La iddingsita es un mineral de alteración que se forma a partir del olivino bajo condiciones de baja temperatura e hidratación, y es menos común que su mineral precursor. En Cofrentes, la iddingsita aparece como un mineral terroso de color marrón o rojizo, destacando en las zonas donde el olivino ha experimentado procesos de alteración.

Estos minerales son de particular interés porque ofrecen una visión clara de los procesos post-eruptivos que han afectado al Cerro de Agras. La transformación de olivino en iddingsita es un indicador de la interacción entre el magma y el entorno en el que se enfría, proporcionando información valiosa sobre la historia geológica del volcán.
        `
    }
  ];
  
  const markers = [
    { id: 1, position: [38.4526526, -0.7715992], description: 'Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito.', image: Petrer, title: "Argonitos en Loma Badá, Petrer", moreInfoLink: "/blog/2"},
    { id: 2, position: [38.5769537, -0.2589064], description: 'A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela.', image: Finestrat, title: "Jacintos de compostela en la carretera de Orxeta-Finestrat", moreInfoLink: "/blog/1" },
    { id: 3, position: [38.4960296126089, -0.5824822022584811], description: 'La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre.', image: Tibi, title: "Azufre en la mina San Francisco, Tibi", moreInfoLink: "/blog/3" },
    { id: 4, position: [38.5615094, -0.2186378], description: 'El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita.', image: Orxeta, title: "Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat", moreInfoLink: "/blog/4" },
    { id: 5, position: [39.2391161, -1.0700520], description: ' El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita.', image: Cofrentes, title: "Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes", moreInfoLink: "/blog/5" }
  ];

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage posts={posts} />} />
        <Route path="/blog/:id" element={<BlogPost posts={posts} />} />
        <Route path="/map" element={<MapPage markers={markers} />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
